import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import CardFlicker from '../component/CardFlickr'
import CardFlickerLoading from '../component/CardFlickrLoading'


const ListFlickr = ({ type, entry }) => {

  const renderCardRight = (type, entry) => {

    if(type === "sukses"){
      if(entry.length === 0){
        return(
            <CardFlickerLoading />
        )
      }else{
        const item = []
        for(let t=0; t < entry.length / 2; t++){
              item.push(<CardFlicker dataFlickr={entry[t]} key={t} />)
              item.push(<br key={'br'+t} />)
        }
        return item
      }
    }

    if(type === "danger"){
      return(
          <CardFlickerLoading />
      )
    } 
  }

  const renderCardLeft= (type, entry) => {

    if(type === "sukses"){
      if(entry.length === 0){
        return(
            <CardFlickerLoading />
        )
      }else{
        const item = []
        for(let t= entry.length / 2; t < entry.length; t++){
              item.push(<CardFlicker dataFlickr={entry[t]} key={t} />)
              item.push(<br key={'br'+t} />)
        }
        return item
      }
    }

    if(type === "danger"){
      return(
          <CardFlickerLoading />
      )
    } 
  }

  return(
    <Container>
      <Row>    
        <Col xs={12} lg={6}>
          {renderCardRight(type,entry)}
        </Col>
        <Col xs={12} lg={6}>
          {renderCardLeft(type,entry)}
        </Col>
      </Row>
    </Container>
  )
}

export default ListFlickr;