import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap'
import Axios from 'axios';

import AlertFetch from '../component/AlertFetch';
import ListFlickr from '../container/ListFlickr';


const Flickr = ({ url, loading }) => {
  const [dataFlicker, setDataFlickr] 
  = useState(null);

  let description = "Your data is loading....."
  let listDataFlickr = []
  
  useEffect(() => {
    let mounted = true;
    if(!loading) {
      const loadData = async () => {
        try{
          const response = await Axios.get(url,
            {
              proxy: { host: '127.0.0.1', port: 5001 },
              headers: {
              'Access-Control-Allow-Origin': '*',
              'Cache-Control': 'no-cache',
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8'
            }
          })
          if(mounted){
            setDataFlickr(response.data)
          }
        }catch(e){
          console.log(`error: ${JSON.stringify(e)}`)
        }
      }
      loadData();
    }else{
        setDataFlickr(null)
    }
      return () => {
        mounted = false;
      }
  },[url,loading]);

  if(!dataFlicker) {
    return (
      <Container>
        <AlertFetch type="danger" title="Please Wait...." description={description} />
        <ListFlickr type="danger" entry={listDataFlickr} />
      </Container>
    )
  }

  description = "Updated : " + dataFlicker.data.feed.updated;
  listDataFlickr = dataFlicker.data.feed.entry;

  return (
    <Container>
      <AlertFetch type="success" title={dataFlicker.data.feed.title} description={description} />
      <ListFlickr type="sukses" entry={listDataFlickr} />
    </Container>
  );
}

export default Flickr;