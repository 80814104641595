import React from 'react'
import { Card, ListGroup, ListGroupItem } from 'react-bootstrap';

import classes from './CardFlickr.module.css';


const CardFlickr = ({dataFlickr}) => {
  return(
    <Card className={classes.Container}>
      <Card.Header>{dataFlickr.title}</Card.Header>  
      <Card.Body>
        <Card.Text>
          <div dangerouslySetInnerHTML={{__html:dataFlickr.content.text}} />
        </Card.Text>
      </Card.Body>
      <ListGroup className="list-group-flush">
          <ListGroupItem></ListGroupItem>
          <ListGroupItem> Author : {dataFlickr.author.name}</ListGroupItem>
          <ListGroupItem><a href={dataFlickr.author.uri}>{dataFlickr.author.uri}</a></ListGroupItem>
      </ListGroup>
      <Card.Footer className={classes.Footer}>
        {dataFlickr.published} 
      </Card.Footer>
    </Card>
  )
}

export default CardFlickr;