import React from 'react'
import { Form, FormControl, Button, Container } from 'react-bootstrap';

const FormMenu = ({handleSearch, handleRefresh, loading}) => {

  const ButtonRender = (loading) => {
    if(loading){
      return (<Button class="pull-right" variant="outline-danger" onClick={handleRefresh} disabled><i className="fa fa-refresh fa-spin" /></Button>)
    }else{
      return (<Button class="pull-right" variant="outline-danger" onClick={handleRefresh}><i className="fa fa-refresh" /></Button>)
    }

  }
  return(
      <Form inline onSubmit={handleSearch}>
          <FormControl type="text" placeholder="tags: car, mountains, etc" name="tags" className="mr-sm-2" />
            <Button variant="outline-primary" type="submit"><i className="fa fa-search" /></Button>
            {ButtonRender(loading)}
      </Form>
  )
}

export default FormMenu;