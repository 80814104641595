import React from 'react';
import { Container, Row, Col, Navbar, Button } from 'react-bootstrap'

import FormMenu from '../../component/FormMenu';


const Menu = ({ handleSearch, handleRefresh, loading }) => {

  

  return (
      <Container>
        <Row>
          <Col>
            <Navbar className="bg-none justify-content-between">
                <Navbar.Brand>Flickr API</Navbar.Brand>
                <FormMenu handleSearch={handleSearch} loading={loading} handleRefresh={handleRefresh} />
              
            </Navbar>
          </Col>
        </Row>
      </Container>
  )
}

export default Menu;