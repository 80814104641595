import React from 'react'
import { Card, ListGroup, ListGroupItem } from 'react-bootstrap';

import classes from './CardFlickr.module.css';


const CardFlickrLoading = () => {
  return(
    <Card className={classes.Container}>
      <Card.Header>Please Wait...</Card.Header>  
      <Card.Body>
        <Card.Text>
          <p>LOADING.....</p>
        </Card.Text>
      </Card.Body>
      <ListGroup className="list-group-flush">
          <ListGroupItem></ListGroupItem>
          <ListGroupItem></ListGroupItem>
          <ListGroupItem></ListGroupItem>
      </ListGroup>
      <Card.Footer className={classes.Footer}>
      </Card.Footer>
    </Card>
  )
}

export default CardFlickrLoading;