import React, { useState, useContext }  from 'react';
import { Row } from 'react-bootstrap';
import Flickr from './api/Flickr'
import Menu from './container/Navbar/Menu'


import './App.css';
import urlContext from './context/url-context';

const App =() => {

  let flickUrl = useContext(urlContext).url

  const [url, setUrl] 
  = useState(flickUrl)

  const [isLoading, setLoadingStatus]
  = useState(false)

  const handleTags = (e) => {
    e.preventDefault()
    if(!e.target.tags.value) setUrl(flickUrl)
    setUrl(flickUrl + "?tags=" + e.target.tags.value)
  }


  const handleRefresh = () => {
    setLoadingStatus(true)
    setInterval(() => setLoadingStatus(false), 2000)
  } 

  return (
    <Row>
      <Menu handleSearch={(event) => handleTags(event)} loading={isLoading} handleRefresh={() => handleRefresh()} />
      <Flickr url={url} loading={isLoading} />
   </Row>
  );
}

export default App;
