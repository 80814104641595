import React from 'react';
import { Container, Alert } from 'react-bootstrap';

import classes from './AlertFetch.module.css';

const AlertFetch  = ({ type, title, description }) => {
  return(
    <Container>
      <Alert variant={type}>
         <Alert.Heading className={classes.Title}>{title}</Alert.Heading>
         <hr width="50%"/>
          <p className={classes.Description}>
            {description}
          </p>
        </Alert>
    </Container>
  );
}

export default AlertFetch;